import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import HotelDetail from '../../Pages/Hotels/HotelDetail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationArrow,
  faStar,
  faGlobe,
  faCheck,
  faArrowRight,
  faInfo,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import moment from 'moment'
import iLoading from '../../Images/Loading/Loader.gif'
import Loading from '../Loading/Loader'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../GlobalData/GlobalData'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
function HotelCard ({ hotelid }) {
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  var apitoken = Hotelapitoken()
  const [searchData, setSearchData] = useState({
    checkin: '',
    checkout: '',
    adult: 0,
    child: 0
  })
  var filteredHotels = []
  const navigate = useNavigate()
  const [Loadingpage, setLoadingpage] = useState(false)
  const [visibleHotels, setVisibleHotels] = useState(10)
  const [hotelDetails, setHotelDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [baseCName, setBaseCName] = useState('GBP')
  const [baseCurrency, setBaseCurrency] = useState([])
  const [hotelsListing, setHotelsListing] = useState([])
  const [showPrice, setShowPrice] = useState(true)
  const hotels = useSelector(state => state.hotels.hotels);
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  useEffect(() => {
    // Your code to be executed every time hotelsSearchData changes
    // For example:

    // Add your logic here based on the updated hotelsSearchData
  }, [ReduxSearchData])
  useEffect(() => {
    if (hotelid !== '') {
      setLoadingpage(true)
      if (typeof hotelid === 'number') {
        filteredHotels = hotels.hotels_list.filter(
          x => x.hotel_id === Number(hotelid)
        )
        setHotelsListing(filteredHotels)
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      } else if (hotelid.type === 'Stars') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const hotelRating = hotel.stars_rating
          if (hotelRating !== '') {
            return Object.keys(hotelid).some(
              ratingKey => Number(hotelRating) === Number(hotelid[ratingKey])
            )
          }
        })
        setHotelsListing(filteredHotels)
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      } else if (hotelid.type === 'meal') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return hotel.rooms_options.some(room => {
            for (const mealKey in hotelid) {
              if (room.board_id === hotelid[mealKey]) {
                return true // Found a match, include the hotel
              }
            }
            return false // No match found for any meal key
          })
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      } else if (hotelid.type === 'facility') {
        const filteredHotels = []
        hotels.hotels_list.forEach(hotel => {
          Facilityfilter(hotel.hotel_id, hotel.hotel_provider, metaData => {
            // Check if the hotel has the desired facilities (e.g., wifi and safe).
            for (const mealKey in hotelid) {
              if (metaData.facilities.includes(hotelid[mealKey])) {
                filteredHotels.push(hotel)
              }
            }
          })
        })
        setLoadingpage(false)
        setHotelDetails({})
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setHotelsListing(filteredHotels)
      } else if (hotelid.type === 'price') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const price = hotel.min_price
          return price >= hotelid.min && price <= hotelid.max
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        filteredHotels.forEach(hotel => {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        })
        setLoadingpage(false)
      }
    }
  }, [hotelid])
  const Facilityfilter = async (hotelId, provider, callback) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }
    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
          // Required for CORS support to work
          //  "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
          // "Access-Control-Allow-Headers":
          // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
          // "Access-Control-Allow-Methods": "POST, OPTIONS"
        }
      })
      callback(response.data.details_data)
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    setSearchData(prevdata => ({
      ...prevdata,
      adult: ReduxSearchData.adult,
      child: ReduxSearchData.child,
      checkin: moment(ReduxSearchData.check_in).format('Do MMM '),
      checkout: moment(ReduxSearchData.check_out).format('Do MMM ')
    }))
    // Add a scroll event listener to the window.
    window.addEventListener('scroll', handleScroll)
    return () => {
      // Remove the scroll event listener when the component unmounts.
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    var curency = hotels.hotels_list[0].hotel_curreny;
    AllCurrency(curency);
  }, [])
  const RoomDetailPage = async (id, index) => {
    const hotelRoomdetail = hotels.hotels_list.filter(
      item => item.hotel_id == id
    )
    setLoadingpage(true)
    try {
      const data = {
        token: apitoken,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        endpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )

      // Handle the API response here
      dispatch(fetchHotelDetail(response.data.hotel_details))
      navigate(`/hotel_detail/${id}`, { state: { index } })
      setLoadingpage(false)
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };

  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }

    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
          // Required for CORS support to work
          //  "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
          // "Access-Control-Allow-Headers":
          // "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
          // "Access-Control-Allow-Methods": "POST, OPTIONS"
        }
      })
      setHotelDetails(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };
  
  const loadMoreHotels = () => {
    setVisibleHotels(prevVisibleHotels => prevVisibleHotels + 10)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    hotels.hotels_list
      .slice(visibleHotels - 10, visibleHotels)
      .forEach(hotel => {
        if (!hotelDetails[hotel.hotel_id]) {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        }
      })
  }, [visibleHotels])

  const handleScroll = () => {
    // Check if the user has scrolled to the bottom of the page
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.offsetHeight
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if (documentHeight - (windowHeight + scrollTop) < 800) {
      // Load more hotels when the user reaches the bottom
      if (!loading) {
        loadMoreHotels()
      }
    }
  }
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      const gbpprice = baseCurrency[baseCName] // Use square brackets to access the property
      var baseprice = (Number(gbpprice) * Number(price)).toFixed(0)
    } else {
      var select123 = CurrencyRates.selectedcurrency
      const gbpprice = baseCurrency[baseCName]
      var baseprice1 = (Number(gbpprice) * Number(price)).toFixed(0)
      const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = (Number(gbpprice2) * Number(baseprice1)).toFixed(0)
    }
    return baseprice
  };

  const AllCurrency = curency => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + curency, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }

    Axios.request(config)
      .then(response => {
        // Handle the response data
        setBaseCurrency(response.data.conversion_rates)
        localStorage.setItem('HotelCurrency', JSON.stringify(response.data))
      })
      .catch(error => {
        // Handle errors here
        if(localStorage.getItem('HotelCurrency') !== null) {
          localStorage.removeItem('HotelCurrency');
          }
        setShowPrice(false)
        console.error(error)
      })
  };
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    return finalpricemarkup
  };

  return (
    <>
      {Loadingpage && <Loading />}
      <div>
        {hotelid === '' ? (
          <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
              {hotels.hotels_list.slice(0, visibleHotels).map((item, index) => (
                <div key={item.hotel_id} className='row'>
                  <div className='col-md-12 mb-4'>
                    <div className='row parent_row'>
                      <div className='col-md-4 item-from hotel-img-top'>
                        {hotelDetails[item.hotel_id] &&
                        hotelDetails[item.hotel_id].details_data &&
                        hotelDetails[item.hotel_id].details_data.image ? (
                          <div>
                            <img
                              className='tour-img'
                              src={
                                hotelDetails[item.hotel_id].details_data.image
                              }
                              alt=''
                            />
                          </div>
                        ) : (
                          // <ShimmerThumbnail height={250} />
                          <img src={iLoading} />
                        )}
                          <div class='room-avail'>
                          <i class='awe-icon awe-icon-marker-2'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>{' '}
                          {item.rooms_options.length} Rooms Available{' '}
                        </div>
                      </div>

                      <div className='col-md-5'>
                        <h5 className='card-title mt-2'>
                          <a
                            onClick={() => RoomDetailPage(item.hotel_id, index)}
                            className='p-card-title'
                          >
                            {item.hotel_name}
                          </a>
                        </h5>
                        <h6 className='departure-date mb-0'>
                          {searchData.checkin}{' '}
                          <FontAwesomeIcon icon={faArrowRight} />{' '}
                          {searchData.checkout}
                        </h6>
                        <p className='card-star'>
                          {item.stars_rating === '' ? (
                            <span className='fw-bold'>No Rating</span>
                          ) : (
                            Array(item.stars_rating)
                              .fill(0)
                              .map((_, index) => (
                                <i key={index} className='fa fa-star'>
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              ))
                          )}
                        </p>
                        {hotelDetails[item.hotel_id] && (
                          <div class='item-address'>
                            <i class='awe-icon awe-icon-marker-2'>
                              <FontAwesomeIcon icon={faLocationArrow} />
                            </i>{' '}
                            {hotelDetails[item.hotel_id].details_data.address}{' '}
                          </div>
                        )}
                       
                      
                      </div>
                      <div className='col-md-3 text-center card-price-section  pt-2'>
                      
                        <div className='price text-center p-card-price'>
                          {showPrice ? (
                            <h6>
                              <super>
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  )
                                )}
                              </super>
                              {/* <sub>PP</sub> */}
                            </h6>
                          ) : (
                            <h6>
                              <super>
                                {item.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ).toFixed(2)}
                              </super>
                              {/* <sub>PP</sub> */}
                            </h6>
                          )}
                        </div>
                        <div className='time_length'>
                          <i className='fa fa-moon-o' aria-hidden='true'></i>
                          {searchData.adult}-Adults, {searchData.child}-Children
                          {/* <span className='tour_length'>12 Night</span> */}
                        </div>

                        <button
                          className='btn btn-primary select-styling search-btn1 form-control'
                          onClick={() => RoomDetailPage(item.hotel_id, index)}
                        >
                          Book Now
                        </button>

                        <div style={{ fontSize: '11px' }}>
                          <FontAwesomeIcon
                            color='#bd1c1cc9'
                            icon={faInfoCircle}
                          />{' '}
                          Inclusive of VAT and Taxes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='filter-page__content'>
            <div className='filter-item-wrapper' id='tours_filter'>
              {hotelsListing.slice(0, visibleHotels).map((item, index) => (
                <div key={item.hotel_id} className='row'>
                  <div className='col-md-12 mb-4'>
                    <div className='row parent_row'>
                      <div className='col-md-3 item-from'>
                        {hotelDetails[item.hotel_id] &&
                        hotelDetails[item.hotel_id].details_data &&
                        hotelDetails[item.hotel_id].details_data.image ? (
                          <div>
                            <img
                              className='tour-img'
                              src={
                                hotelDetails[item.hotel_id].details_data.image
                              }
                              alt=''
                            />
                          </div>
                        ) : (
                          // <ShimmerThumbnail height={250} />
                          <img src={iLoading} />
                        )}
                      </div>

                      <div className='col-md-6'>
                        <h5 className='card-title mt-2'>
                          <a
                            onClick={() => RoomDetailPage(item.hotel_id, index)}
                            className='p-card-title'
                          >
                            {item.hotel_name}
                          </a>
                        </h5>
                        <h6 className='departure-date mb-0'>
                          {searchData.checkin}{' '}
                          <FontAwesomeIcon icon={faArrowRight} />{' '}
                          {searchData.checkout}
                        </h6>
                        <p className='card-star'>
                          {item.stars_rating === '' ? (
                            <span className='fw-bold'>No Rating</span>
                          ) : (
                            Array(item.stars_rating)
                              .fill(0)
                              .map((_, index) => (
                                <i key={index} className='fa fa-star'>
                                  <FontAwesomeIcon icon={faStar} />
                                </i>
                              ))
                          )}
                        </p>
                        {hotelDetails[item.hotel_id] && (
                          <div class='item-address'>
                            <i class='awe-icon awe-icon-marker-2'>
                              <FontAwesomeIcon icon={faGlobe} />
                            </i>{' '}
                            {hotelDetails[item.hotel_id].details_data.address}{' '}
                          </div>
                        )}
                         {hotelDetails[item.hotel_id] ? (
                          <div class='row'>
                            {hotelDetails[
                              item.hotel_id
                            ].details_data.facilities.map((facility, index) => (
                              <div
                                key={index}
                                className=' col-sm-4 col-4 col-md-4 col-lg-4 mt-1'
                              >
                                <div class='single-tour-feature d-flex align-items-center mb-3'>
                                  <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                    <i class='fas fa-check'>
                                      <FontAwesomeIcon icon={faCheck} />
                                    </i>
                                  </div>
                                  <div class='single-feature-titles'>
                                    <p class='title fw-bold'>{facility}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ):(
                        <div className='placeholder-glow'>
                          <span className='placeholder col-12'></span>
                          <span className='placeholder col-12'></span>
                          <span className='placeholder col-6'></span>

                        </div>
                        )}
                        <div class='item-address' style={{ color: 'green' }}>
                          <i class='awe-icon awe-icon-marker-2'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>{' '}
                          {item.rooms_options.length} Rooms Available{' '}
                        </div>
                      </div>
                      <div className='col-md-3 text-center card-price-section  pt-2'>
                        <h6 className='text-center p-view-detail'>Map View</h6>
                        <div className='price text-center p-card-price'>
                          {showPrice ? (
                            <h6>
                              <super>
                                {CurrencyRates === undefined
                                  ? baseCName
                                  : CurrencyRates.selectedcurrency}{' '}
                                {renderPrice(
                                  calculateMarkup(
                                    item.min_price,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  )
                                )}
                              </super>
                              {/* <sub>PP</sub> */}
                            </h6>
                          ) : (
                            <h6>
                              <super>
                                {item.hotel_curreny}{' '}
                                {calculateMarkup(
                                  item.min_price,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                ).toFixed(2)}
                              </super>
                              {/* <sub>PP</sub> */}
                            </h6>
                          )}
                        </div>
                        <div className='time_length'>
                          <i className='fa fa-moon-o' aria-hidden='true'></i>
                          {searchData.adult}-Adults, {searchData.child}-Children
                          {/* <span className='tour_length'>12 Night</span> */}
                        </div>

                        <button
                          className='btn btn-primary select-styling search-btn1 form-control'
                          onClick={() => RoomDetailPage(item.hotel_id, index)}
                        >
                          Book Now
                        </button>

                        <div style={{ fontSize: '11px' }}>
                          <FontAwesomeIcon
                            color='#bd1c1cc9'
                            icon={faInfoCircle}
                          />{' '}
                          Inclusive of VAT and Taxes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default HotelCard
