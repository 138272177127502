export function Hotelapitoken(){
    return 'EqYTaKoioN5aOkBQIV0Zlk2EBsGhKf3ldNc29lxH-Sbl8Kq10d5WDzmCzgCBK68t4et218Nhp0a5amjyJQtj0maYZOIwO49zu18C0-L2cIqVcq98hr89a7fL8WKaUhr-bk2sTjO94sBDyxSyKWPWUqf7Ly4eTniwtNuh5H7VSUPnOoS3iQOqXo2pj1G1DD8isp0U1aDwAXb-sGqtvFU6x0eDeETPKrgVvZDHBRVVnTLK3kv9hmhL2biGxPl901wpyeSdcvs0dZisGPejyfvUAMIFKjlnqDsL0LnrM2S7CUaC72UO-ffUu2yoNagLpUAVv5AWDPRboAWZ01ekyQx1WHKGbr8VrzISoEen4mTEL25ZhHWcR7qjYUh1Jwsq-RoyjwocX2wwvipevdWNueOruc-8n8qNieP1AX6MZuRFXnpU4tZouXhuqVA01FMSfWYGwKVXieXjz-nQL3JvgPXK5fQZDPLcmtG8DU3GqrtdjLFChs1pqXNjg5BcBwMa'
};

export function CustomerDomainName(){
    return 'https://system.alhijaztours.net/'
};
export function FlightSearchToken(){
    return 'EqYTaKoioN5aOkBQIV0Zlk2EBsGhKf3ldNc29lxH-Sbl8Kq10d5WDzmCzgCBK68t4et218Nhp0a5amjyJQtj0maYZOIwO49zu18C0-L2cIqVcq98hr89a7fL8WKaUhr-bk2sTjO94sBDyxSyKWPWUqf7Ly4eTniwtNuh5H7VSUPnOoS3iQOqXo2pj1G1DD8isp0U1aDwAXb-sGqtvFU6x0eDeETPKrgVvZDHBRVVnTLK3kv9hmhL2biGxPl901wpyeSdcvs0dZisGPejyfvUAMIFKjlnqDsL0LnrM2S7CUaC72UO-ffUu2yoNagLpUAVv5AWDPRboAWZ01ekyQx1WHKGbr8VrzISoEen4mTEL25ZhHWcR7qjYUh1Jwsq-RoyjwocX2wwvipevdWNueOruc-8n8qNieP1AX6MZuRFXnpU4tZouXhuqVA01FMSfWYGwKVXieXjz-nQL3JvgPXK5fQZDPLcmtG8DU3GqrtdjLFChs1pqXNjg5BcBwMa'
};

export function ApiEndPoint(){
    return 'https://api.synchtravel.com'
};
export function TransferApiToken(){
    return 'EqYTaKoioN5aOkBQIV0Zlk2EBsGhKf3ldNc29lxH-Sbl8Kq10d5WDzmCzgCBK68t4et218Nhp0a5amjyJQtj0maYZOIwO49zu18C0-L2cIqVcq98hr89a7fL8WKaUhr-bk2sTjO94sBDyxSyKWPWUqf7Ly4eTniwtNuh5H7VSUPnOoS3iQOqXo2pj1G1DD8isp0U1aDwAXb-sGqtvFU6x0eDeETPKrgVvZDHBRVVnTLK3kv9hmhL2biGxPl901wpyeSdcvs0dZisGPejyfvUAMIFKjlnqDsL0LnrM2S7CUaC72UO-ffUu2yoNagLpUAVv5AWDPRboAWZ01ekyQx1WHKGbr8VrzISoEen4mTEL25ZhHWcR7qjYUh1Jwsq-RoyjwocX2wwvipevdWNueOruc-8n8qNieP1AX6MZuRFXnpU4tZouXhuqVA01FMSfWYGwKVXieXjz-nQL3JvgPXK5fQZDPLcmtG8DU3GqrtdjLFChs1pqXNjg5BcBwMa'
};
export function TransferCheckoutToken(){
    return 'EqYTaKoioN5aOkBQIV0Zlk2EBsGhKf3ldNc29lxH-Sbl8Kq10d5WDzmCzgCBK68t4et218Nhp0a5amjyJQtj0maYZOIwO49zu18C0-L2cIqVcq98hr89a7fL8WKaUhr-bk2sTjO94sBDyxSyKWPWUqf7Ly4eTniwtNuh5H7VSUPnOoS3iQOqXo2pj1G1DD8isp0U1aDwAXb-sGqtvFU6x0eDeETPKrgVvZDHBRVVnTLK3kv9hmhL2biGxPl901wpyeSdcvs0dZisGPejyfvUAMIFKjlnqDsL0LnrM2S7CUaC72UO-ffUu2yoNagLpUAVv5AWDPRboAWZ01ekyQx1WHKGbr8VrzISoEen4mTEL25ZhHWcR7qjYUh1Jwsq-RoyjwocX2wwvipevdWNueOruc-8n8qNieP1AX6MZuRFXnpU4tZouXhuqVA01FMSfWYGwKVXieXjz-nQL3JvgPXK5fQZDPLcmtG8DU3GqrtdjLFChs1pqXNjg5BcBwMa'
};
export function CurrencyConverter(){
    return 'd9c44ce6f7a3ac2c11e79b9f'
};
export function StripeClientSecret(){
    // return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    return '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'
};
export function ActivityToken(){
    return 'EqYTaKoioN5aOkBQIV0Zlk2EBsGhKf3ldNc29lxH-Sbl8Kq10d5WDzmCzgCBK68t4et218Nhp0a5amjyJQtj0maYZOIwO49zu18C0-L2cIqVcq98hr89a7fL8WKaUhr-bk2sTjO94sBDyxSyKWPWUqf7Ly4eTniwtNuh5H7VSUPnOoS3iQOqXo2pj1G1DD8isp0U1aDwAXb-sGqtvFU6x0eDeETPKrgVvZDHBRVVnTLK3kv9hmhL2biGxPl901wpyeSdcvs0dZisGPejyfvUAMIFKjlnqDsL0LnrM2S7CUaC72UO-ffUu2yoNagLpUAVv5AWDPRboAWZ01ekyQx1WHKGbr8VrzISoEen4mTEL25ZhHWcR7qjYUh1Jwsq-RoyjwocX2wwvipevdWNueOruc-8n8qNieP1AX6MZuRFXnpU4tZouXhuqVA01FMSfWYGwKVXieXjz-nQL3JvgPXK5fQZDPLcmtG8DU3GqrtdjLFChs1pqXNjg5BcBwMa'
};
export function HajjApiToken(){
    return 'EqYTaKoioN5aOkBQIV0Zlk2EBsGhKf3ldNc29lxH-Sbl8Kq10d5WDzmCzgCBK68t4et218Nhp0a5amjyJQtj0maYZOIwO49zu18C0-L2cIqVcq98hr89a7fL8WKaUhr-bk2sTjO94sBDyxSyKWPWUqf7Ly4eTniwtNuh5H7VSUPnOoS3iQOqXo2pj1G1DD8isp0U1aDwAXb-sGqtvFU6x0eDeETPKrgVvZDHBRVVnTLK3kv9hmhL2biGxPl901wpyeSdcvs0dZisGPejyfvUAMIFKjlnqDsL0LnrM2S7CUaC72UO-ffUu2yoNagLpUAVv5AWDPRboAWZ01ekyQx1WHKGbr8VrzISoEen4mTEL25ZhHWcR7qjYUh1Jwsq-RoyjwocX2wwvipevdWNueOruc-8n8qNieP1AX6MZuRFXnpU4tZouXhuqVA01FMSfWYGwKVXieXjz-nQL3JvgPXK5fQZDPLcmtG8DU3GqrtdjLFChs1pqXNjg5BcBwMa'
};


// 3f5b6c0c70393cf67ceac594
// d9c44ce6f7a3ac2c11e79b9f