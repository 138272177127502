import React, { useState, useEffect } from 'react'
import bgimage from '../../Images/Transfer/transfercover.jfif'
import Layout from '../../Components/Layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { RangeSlider } from 'rsuite'
import TransferCard from '../../Components/Transfer/TransferCard'
function TransferListing () {
  var minValue1 = 0
  var maxValue1 = 0
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const TransferDetail = useSelector(state => state.hotels.TrSeResponse)
  console.log(TransferDetail)
  if (TransferDetail?.transfers_list !== 0) {
    var amounts = TransferDetail?.transfers_list.map(
      item => item.total_fare_markup
    )
    if (amounts.length !== 0) {
      minValue1 = Math.min(...amounts)
      maxValue1 = Math.max(...amounts)
    }
  }
  const [minValue, setMinValue] = useState(minValue1)
  const [maxValue, setMaxValue] = useState(maxValue1)
  const [SessionID, setSessionID] = useState(TransferDetail?.sessionID)
  const [rangeValue, setRangeValue] = useState([minValue1, maxValue1])
  const [FilterData, setFilterData] = useState(TransferDetail?.transfers_list)
  const [vehicleClass, setVehicleClass] = useState([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const handleChange = newRangeValue => {
    setRangeValue(newRangeValue)
    setMinValue(newRangeValue[0])
    setMaxValue(newRangeValue[1])
  }
  useEffect(() => {
    VehicleClassfilter()
  }, [TransferDetail])

  const VehicleClassfilter = () => {
    const VehicleCount = {}
    for (const vehicle of TransferDetail?.transfers_list) {
      const vehicleType = vehicle.vehicle_Name
      if (vehicleType !== undefined) {
        if (VehicleCount.hasOwnProperty(vehicleType)) {
          VehicleCount[vehicleType]++
        } else {
          VehicleCount[vehicleType] = 1
        }
      }

      setVehicleClass(VehicleCount)
    }
  }
  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, name])
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== name))
    }
  }

  const filterbyClass = () => {
    if (selectedCheckboxes.length !== 0) {
      const filtered = TransferDetail?.transfers_list.filter(item =>
        selectedCheckboxes.includes(item.vehicle_Name)
      )
      setFilterData(filtered)
    } else {
      setFilterData(TransferDetail?.transfers_list)
    }
  }

  const filterbyPrice = () => {
    const filteredTours = TransferDetail?.transfers_list.filter(item => {
      const price = item.total_fare_markup
      return price >= minValue && price <= maxValue
    })
    setFilterData(filteredTours)
  }
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice = price
    } else {
      const conversionrate =
        GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency]
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0)
      var baseprice = newprice
    }
    return baseprice
  }

  return (
    <>
      <Layout>
        <div className='contact-img'>
          <img src={bgimage} />
        </div>
        <div className='container'>
          <div className='row mt-3 hotel-top'>
            <div className='col-md-6 '>
              <h3 className='title font-size-24 tc' id='tours_result'>
                {TransferDetail?.transfers_list.length} Result found
              </h3>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-3 col-md-pull-9'>
              <div className='page-sidebar '>
                <div className='widget widget_price_filter'>
                  <div className='mb-0'>
                    <label className='form-label'>Price Filter</label>
                    <RangeSlider
                      value={rangeValue}
                      onChange={handleChange}
                      min={minValue1}
                      tooltip={false}
                      max={maxValue1}
                      step={1}
                    />
                    <div className='pt-2'>
                      <div className='fw-bold mb-2'>
                        Min:{' '}
                        <span id='kt_slider_basic_min'>
                          {renderPrice(minValue)}
                        </span>
                      </div>
                      <div className='fw-bold mb-2'>
                        Max:{' '}
                        <span id='kt_slider_basic_max'>
                          {renderPrice(maxValue)}
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={filterbyPrice}
                      className='btn select-styling search-btn1 mb-1'
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <div
                  className='widget widget_has_radio_checkbox'
                  style={{ borderBottom: '0' }}
                >
                  <h3>Vehicles</h3>
                  <ul>
                    {Object.entries(vehicleClass).map(([code, count]) => (
                      <li key={code}>
                        <label>
                          <input
                            type='checkbox'
                            className='custom-textbox'
                            checked={selectedCheckboxes.includes(code)} // Check if checkbox is selected
                            onChange={handleCheckboxChange}
                            name={code}
                            value={code}
                          />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'>
                            {' '}
                            {code} ({count})
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={filterbyClass}
                    className='btn btn-success btn-block search-btn1'
                  >
                    Filter
                  </button>
                </div>
                {/* <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                <h3>Star Rating</h3>                
                <FontAwesomeIcon icon={faAngleDown}/>
                </div>
               <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox'  name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button  className='btn select-styling search-btn1'>Filter</button>
                </div>
                
              </div> */}
              </div>
            </div>
            <div className='col-md-9 col-md-push-3'>
              {TransferDetail.transfers_list.length === 0 ? (
                <h4 className='text-center'>
                  Sorry! no transfer found in given search.
                </h4>
              ) : (
                <TransferCard TransferData={FilterData} sessionID={SessionID} />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TransferListing
